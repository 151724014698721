import React, { memo, useCallback, useMemo, useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  Link,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DateTimePicker } from '@mui/x-date-pickers';

import { CustomColorPicker } from 'MapManagement/core';
import { useMainContext } from 'ReusableComponents';
import moment from 'moment';

const useStyles = makeStyles({ name: 'AddFeatureProperties' })((theme) => ({
  root: {},
  inputRow: {
    width: '100%',
    margin: 0,
    alignItems: 'flex-start',
    padding: '4px 16px',
    boxSizing: 'border-box',
  },
  formLabel: {
    fontWeight: 'bold',
    color: theme.palette.common.gray1,
    '& span': { fontWeight: 'normal', textTransform: 'initial' },
  },
  disablePadding: { padding: 0 },
}));

const isValidHttpUrl = (string) => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
};

export const AddFeatureProperties = ({
  properties,
  onPropertyValueChange,
  errors,
  isEdit,
  copiedProperties,
  editing,
  isUpload,
  layerInfo,
  isExternal,
}) => {
  const { classes: styles } = useStyles();

  return (
    <>
      {properties?.map((p) =>
        !!editing ? (
          <ValueEdit
            key={p?.id}
            value={copiedProperties?.[p?.name]}
            error={errors?.[p?.name]}
            onChange={onPropertyValueChange}
            property={p}
          />
        ) : !p.hidden ? (
          <ValueDisplay
            key={p?.id}
            value={copiedProperties?.[p?.name]}
            type={p?.type}
            required={p?.required}
            name={p?.name}
            filtering={p?.filtering}
          />
        ) : null
      )}
      {!isUpload && !isExternal && layerInfo?.style.method === 'fromColorProperty' && (
        <ColorElement editing={editing} color={copiedProperties?.color} onChange={onPropertyValueChange} />
      )}
      {copiedProperties?.id && (
        <ListItem dense margin="none" key={'ID'} component="div" className={styles.inputRow}>
          <ListItemText
            primary={'ID'}
            secondary={copiedProperties?.id}
            primaryTypographyProps={{ noWrap: true, variant: 'button', className: styles.formLabel }}
          />
        </ListItem>
      )}
    </>
  );
};

const ColorElement = ({ editing, color = '#ffffff', onChange }) => {
  const { classes: styles } = useStyles();
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <FormControlLabel
      className={styles.inputRow}
      label={
        <Typography variant="button" className={styles.formLabel}>
          Color
        </Typography>
      }
      labelPlacement="top"
      key={'color'}
      control={
        <CustomColorPicker
          color={color}
          onChange={
            editing
              ? (e) => {
                  onChange(e.hex, { name: 'color', type: 'color' });
                  setShowColorPicker(false);
                }
              : undefined
          }
          showColorPicker={editing && showColorPicker}
          toggleShowColorPicker={editing ? () => setShowColorPicker((prev) => !prev) : undefined}
        />
      }
    />
  );
};

export const ValueDisplay = memo(({ value, type, required, name, filtering, disableTitle, slotProps = {} }) => {
  const { root: rp = {} } = slotProps;

  const { onModalContentChange } = useMainContext();

  const { classes: styles } = useStyles();

  const stringCastedValue = useMemo(
    () => (type === 'date' && !!value ? moment(value).format('LLL') : String(value)),
    [value, type]
  );

  const Title = useMemo(
    () => (
      <>
        {name + (!!required ? '*' : '')}
        {filtering && <span> ({filtering})</span>}
      </>
    ),
    [filtering, name, required]
  );

  const Value = useMemo(
    () =>
      isValidHttpUrl(stringCastedValue) ? (
        <Link
          onClick={() => {
            onModalContentChange(stringCastedValue, false, true);
          }}
          underline="hover"
        >
          {stringCastedValue}
        </Link>
      ) : value === 'undefined' || (stringCastedValue !== '' && stringCastedValue !== 'undefined') ? (
        stringCastedValue
      ) : (
        '-'
      ),
    [stringCastedValue, value]
  );

  return (
    <ListItem dense margin="none" component="div" {...rp}>
      <ListItemText
        primary={!disableTitle && Title}
        secondary={Value}
        primaryTypographyProps={{ noWrap: true, variant: 'button', className: styles.formLabel }}
      />
    </ListItem>
  );
});

const InputLabel = memo(({ name, filtering, required }) => {
  const { classes: styles } = useStyles();

  return (
    <Typography variant="button" className={styles.formLabel}>
      {name + (!!required ? '*' : '')}
      {filtering && <span> ({filtering})</span>}
    </Typography>
  );
});

export const ValueEdit = memo(({ value, onChange, error, property, rowIndex, slotProps: sp = {} }) => {
  const { name, required, filtering } = property;
  const { control: cp = {}, input: ip = {}, label: lp = {} } = sp;
  const { disable: disableLabel = false } = lp;
  const { onSubmit, ...controlSlotProps } = cp;

  const internalOnChange = useCallback(
    (e) => {
      const value =
        property?.levels && property?.levels?.length > 0
          ? e.target.value
          : property?.type === 'date'
          ? e?.toISOString()
          : property?.type === 'string'
          ? e.target.value.toString()
          : property?.type === 'integer'
          ? parseInt(e.target.value)
          : property?.type === 'float'
          ? parseFloat(e.target.value)
          : e;

      onChange(value, property);
    },
    [onChange, property]
  );

  const { Control = BaseTextInput, ...controlProps } = useMemo(
    () =>
      property?.levels && property?.levels?.length > 0
        ? { Control: LevelsInput, levels: property?.levels, onChange: internalOnChange }
        : property.type === 'boolean'
        ? { Control: BoolInput, onChange: internalOnChange }
        : property?.type === 'date'
        ? { Control: DateInput, onChange: internalOnChange }
        : property?.type === 'integer'
        ? { type: 'number', inputProps: { step: 1 }, onInput: internalOnChange }
        : property?.type === 'float'
        ? { type: 'number', inputProps: { step: 0.01 }, onInput: internalOnChange }
        : property?.type === 'string'
        ? { onChange: internalOnChange }
        : {},
    [internalOnChange, property?.levels, property.type]
  );

  return (
    <InputBase
      property={property}
      value={value}
      error={error}
      Label={!disableLabel && <InputLabel name={name} required={required} filtering={filtering} />}
      disableLabel={disableLabel}
      Control={
        <Control
          value={value}
          error={error}
          required={property?.required}
          rowIndex={rowIndex}
          propertyId={property?.id}
          {...controlProps}
          {...controlSlotProps}
          fullWidth
        />
      }
      {...ip}
    />
  );
});

const InputBase = memo(({ Label, Control, property, error, disableLabel, ...ip }) => {
  const { cx, classes: styles } = useStyles();

  const { disablePadding } = ip;

  return !Control ? null : property.type === 'boolean' ? (
    <FormControl
      key={property?.name}
      className={cx(styles.inputRow, disablePadding && styles.disablePadding)}
      variant="standard"
      required={!disableLabel && property?.required}
      error={!!error}
      fullWidth
    >
      {Label}
      {Control}
    </FormControl>
  ) : (
    <FormControlLabel
      key={property?.name}
      className={cx(styles.inputRow, disablePadding && styles.disablePadding)}
      labelPlacement="top"
      label={Label}
      control={Control}
      required={!disableLabel && property?.required}
    />
  );
});

const BoolInput = memo(({ value, onChange, error }) => (
  <>
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={value === false ? true : false}
            onChange={(e, newValue) => onChange(newValue === true ? false : null)}
            name="false"
            color="primary"
          />
        }
        label="False"
        labelPlacement="top"
        sx={{ mx: 1 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value === true ? true : false}
            onChange={(e, newValue) => onChange(newValue === true ? true : null)}
            name="true"
            color="primary"
          />
        }
        label="True"
        labelPlacement="top"
        sx={{ mx: 1 }}
      />
    </FormGroup>
    {error && <FormHelperText>{error}</FormHelperText>}
  </>
));

const LevelsInput = memo(({ value, required, levels, rowIndex, propertyId, ...props }) => (
  <Select key={`${rowIndex}_${propertyId}`} variant="outlined" value={value ?? ''} size="small" {...props}>
    {levels.map((l) => (
      <MenuItem key={l} value={l}>
        {l}
      </MenuItem>
    ))}

    <Divider />
    <MenuItem key={'No Value'} value={undefined}>
      No Value
    </MenuItem>
  </Select>
));

const BaseTextInput = memo(({ required, value, error, rowIndex, propertyId, ...props }) => (
  <TextField
    key={`${rowIndex}_${propertyId}`}
    size="small"
    variant="outlined"
    required={required}
    value={value ?? ''}
    error={!!error}
    helperText={error}
    fullWidth
    {...props}
  />
));

const DateInput = memo(({ value, rowIndex, propertyId, ...props }) => (
  <DateTimePicker
    key={`${rowIndex}_${propertyId}`}
    variant="inline"
    value={!!value ? moment(value) : null}
    slotProps={{
      textField: {
        fullWidth: true,
        size: 'small',
        variant: 'outlined',
      },
    }}
    {...props}
  />
));

BoolInput.displayName = 'BoolInput';
BaseTextInput.displayName = 'BaseTextInput';
DateInput.displayName = 'DateInput';
LevelsInput.displayName = 'LevelsInput';
InputBase.displayName = 'InputBase';
ValueEdit.displayName = 'ValueEdit';
ValueDisplay.displayName = 'ValueDisplay';
InputLabel.displayName = 'InputLabel';
