import { tileLayers } from 'Viewer/TileLayers';
import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';

const VersionUtility = {
  convertPathInfo: convertPathInfo,

  convertListRoot: (res) => {
    //order it
    /*
    res.result.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    */

    for (let i = 0; i < res.result.length; i++) {
      res.result[i] = convertPathInfo(res.result[i]);
    }
    return res;
  },

  convertInvites: (res) => {
    res = res.map((r) => {
      if (r.access) {
        r = { ...r, ...r.access };
        r.subscriptionFee = r.access.monthlyFee;
      }
      r.pathId = r.path.id;
      r.type = r.path.type;
      r.type = r.type === 'raster' ? 'map' : r.type === 'vector' ? 'shape' : r.type;
      r.name = r.path.name;
      r.user = r.path.user;

      return r;
    });
    return res;
  },
  convertUsage: (res) => {
    res = res.map((x) => {
      x.actions = x.processingUnits;
      return x;
    });
    return res;
  },
  convertUploads: (res) => {
    res.result = res.result.map((r) => {
      r.filename = r.name;
      r.fileSize = r.size;
      return r;
    });

    return res;
  },
  convertViewerState: (res) => {
    return res;
  },
  convertListFeatures: (res) => {
    return res;
  },
  convertViewerLayers: (res) => {
    res.path = res.mainPath;
    delete res.mainPath;
    const ps = ['layers', 'dems'];
    for (let i = 0; i < ps.length; i++) {
      let p = ps[i];
      res[p] = res[p].map((x) => {
        if (x.type === 'ellipsis' || p === 'dems') {
          return { ...convertPathInfo(x.pathInfo), isSelected: x.selected };
        } else if (x.type === 'external') {
          return { ...x.userExternalMap, isSelected: x.selected };
        } else {
          return { ...tileLayers().find((l) => l.id === x.baseMap.id), isSelected: x.selected };
        }
      });
    }
    return res;
  },
  convertBookmark: (res) => {
    const ps = ['layers', 'dems'];
    for (let i = 0; i < ps.length; i++) {
      let p = ps[i];
      res[p] = res[p].map((x) => {
        if (x.type === 'ellipsis' || p === 'dems') {
          return { ...convertPathInfo(x.path), isSelected: x.selected };
        } else if (x.type === 'external') {
          return { ...x.userExternalMap, isSelected: x.selected };
        } else {
          return { ...tileLayers().find((l) => l.id === x.baseMap.id), isSelected: x.selected };
        }
      });
    }
    return res;
  },
  convertMessages: (res) => {
    res.result = res.result.map((r) => {
      r.geometryId = r.feature.id;
      r.action = r.type;
      r.deleted = r.feature.trashed;
      r.geometryDeleted = r.feature.trashed;
      return r;
    });
    return res;
  },
  convertTransactions: (res) => {
    res = res.map((t) => {
      t.change = t.amount;
      t.message = t.description;
      return t;
    });

    return res;
  },
  convertAccount: (res) => {
    res.coins = res.balance;
    if (res.client) {
      res.client.clientId = res.client.id;
    }
    if (res.plan) {
      res.commercial =
        ApiManager.cloud !== CLOUDS.ellipsis ? true : res.plan.type === 'pro' || res.plan.type === 'enterprise';
      res.plan.storageLimit = res.plan.maxStorage;
      res.plan.storageFee = res.plan?.monthlyFee?.storageFee;
      res.plan.serviceFee = res.plan?.monthlyFee?.serviceFee;
    }

    res = { ...res, ...res.profile };
    delete res.profile;
    return res;
  },
  convertPlans: (res) => {
    return convertPlans(res);
  },
  convertMembers: (res) => {
    res = res.map((r) => {
      r = { ...r, ...r.access, ...r.access.geoFence };
      delete r.access.geoFence;
      r.actionsMonth = r.processingUnits;
      r.minAccessLevel = r.inheritedAccess.accessLevel;
      r.minActionsMonth = r.inheritedAccess.processingUnits;
      return r;
    });
    return res;
  },
};

function convertPlans(res) {
  res = res.map((r) => {
    r = { ...r, ...r.access };
    r.actionsMonth = r.processingUnits;
    return r;
  });
  return res;
}

function convertPathInfo(res) {
  if (res.metadata?.properties) {
    delete res.metadata.properties;
  }

  if (res[res.type]?.['styles']) {
    res[res.type]['styles'] = res[res.type]['styles'].map((s) => {
      if (s.parameters.transitionPoints) {
        s.parameters.transitionPoints = s.parameters.transitionPoints.sort(function (a, b) {
          return a.value - b.value;
        });
      }
      return s;
    });
  }

  res.plans = convertPlans(res.plans);

  res.user.commercial = ApiManager.cloud !== CLOUDS.ellipsis ? true : res.user.plan.type === 'pro';

  delete res.user.plan;
  res.owner = res.user;
  res.path = res.driveLocation;
  if (res.path.root === 'myDrive') {
    res.path.root = 'myMaps';
  }
  delete res.driveLocation;
  if (res.trashed) {
    res.deleted = res.trashed;
  }
  res.disabled = res.user.disabled;

  res.yourAccess.actionsMonth = res.yourAccess.processingUnits;
  delete res.yourAccess.processingUnits;

  res.yourAccess = { ...res.yourAccess, ...res.yourAccess.geoFence };
  delete res.yourAccess.geoFence;
  res.publicAccess.publicAccessLevel = res.publicAccess.accessLevel;
  res.publicAccess.publicActionsMonth = res.publicAccess.processingUnits;
  res.publicAccess.minPublicAccessLevel = res.inheritedPublicAccess.accessLevel;
  res.publicAccess.minPublicActionsMonth = res.inheritedPublicAccess.processingUnits;
  res.publicAccess = { ...res.publicAccess, ...res.publicAccess.geoFence };
  delete res.publicAccess.geoFence;
  delete res.publicAccess.accessLevel;
  delete res.publicAccess.processingUnits;
  delete res.publicAccess.inheritedAccessLevel;
  delete res.publicAccess.inheritedProcessingUnits;

  res = { ...res, ...res.vector };
  res = { ...res, ...res.raster };
  res = { ...res, ...res.pointCloud };

  delete res.raster;

  res.deleted = res.trashed;
  delete res.trashed;

  res.description = res.metadata.description;
  res.properties = res.metadata.properties;
  res.attribution = res.metadata.attribution;
  res.license = res.metadata.license;
  delete res.metadata;

  if (res.type === 'vector' || res.type === 'raster' || res.type === 'pointCloud') {
    if (res.type === 'raster') {
      res.type = 'map';
    } else if (res.type === 'vector') {
      res.type = 'shape';
    }

    if (res.timestamps) {
      res.timestamps.map((t) => {
        t.date.from = t.date.from.slice(0, -1);
        t.date.to = t.date.to.slice(0, -1);
        t.oldStatus = t.status;
        if (t.trashed) {
          t.status = 'deleted';
        }
        return t;
      });
    }

    let uploadsLoopProperty = res.timestamps ? 'timestamps' : 'layers';
    for (let y = 0; y < res[uploadsLoopProperty].length; y++) {
      let loopElement = res[uploadsLoopProperty][y];

      loopElement.uploadInfo = {};
      delete loopElement.uploads;

      loopElement.deleted = loopElement.trashed;
      delete loopElement.trashed;
    }

    if (res.type === 'shape') {
      res.styles = res.styles.map((s) => {
        return s;
      });
    }

    if (res.vector?.properties) {
      res.vector.properties = res.vector.properties.map((p) => {
        let newP = { ...p };
        if (newP.trashed) {
          newP.deleted = newP.trashed;
        }
        return newP;
      });
    }

    if (res.type === 'map') {
      if (res.layers) {
        res.mapLayers = res.layers;
        delete res.layers;
      } else {
        res.mapLayers = res.styles;
        delete res.layers;
      }
    } else if (res.type === 'shape') {
      res.geometryLayers = res.layers;
      delete res.layers;
    }
  }

  if (res.timestamps) {
    res.timestamps = res.timestamps.map((x) => {
      ///TEMPORARY
      if (x.statistics && x.precompute) {
        x.statistics.precomputed = x.precompute?.hasVectorTiles;
        x.statistics.hasCompressList = x.precompute?.hasCompressedList;

        x.statistics.precomputeZoom = x.precompute.vectorTileZoom;
      }

      /* if (false && x.statistics) {
        x.statistics.precomputed = false;
      } */

      if (x.status === 'active') {
        x.status = 'finished';
      } else if (x.status === 'passive') {
        x.status = 'created';
      } else if (x.status === 'trashed') {
        x.status = 'deleted';
      } else if (x.status === 'deactivating') {
        x.status = 'pausing';
      } else if (!x.status) {
        x.status = 'finished';
      }
      x.dateFrom = x.date?.from;
      x.dateTo = x.date?.to;
      return x;
    });
  }
  return res;
}

export default VersionUtility;
