import React from 'react';

import {
  ButtonBase,
  Box,
  ListItemIcon,
  ListItemText,
  ListItem,
  Stack,
  Zoom,
  ListSubheader,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import SidebarSectionSubs from './SidebarSectionSub';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useStyles = makeStyles()((theme, { selected }) => ({
  root: {
    '& + &': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  button: {},
}));

const SidebarSection = ({ tab, useSidebarSectionLink, rootPath, className = '' }) => {
  const history = useHistory();

  const selected = history.location.pathname.split('/')[2] === tab.path;

  const { classes, cx } = useStyles({ selected });

  const { changeMainMode, mainMode } = useCurrentFolder();

  const handleClick = () => {
    if (mainMode) {
      changeMainMode(`${rootPath}/${tab.path}`);
    } else {
      history.push(`${rootPath}/${tab.path}`);
    }
  };

  const EndIcon = tab?.endIcon;

  if (tab?.type === 'subheading')
    return (
      <Typography
        variant="subtitle2"
        color="text.primary"
        component={ListSubheader}
        sx={{ mt: 1.5, lineHeight: 'unset', py: 0.5 }}
      >
        {tab?.title}
      </Typography>
    );

  return (
    <>
      <Stack flexDirection="row" alignItems="stretch" className={classes.root}>
        <Zoom in={selected}>
          <Box sx={{ bgcolor: 'secondary.main', width: (theme) => theme.spacing(selected ? 0.5 : 0) }} />
        </Zoom>
        <ListItem
          component={ButtonBase}
          className={cx(classes.button, className)}
          onClick={handleClick}
          sx={{ pl: selected ? 1.5 : 2 }}
        >
          <ListItemText primary={tab.name} />
          {!!EndIcon && (
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <Zoom in={!selected}>
                <EndIcon fontSize="inherit" />
              </Zoom>
            </ListItemIcon>
          )}
        </ListItem>
      </Stack>
      {tab.type === 'subs' && (
        <SidebarSectionSubs subs={tab.subs} rootPath={rootPath} useSidebarSectionLink={useSidebarSectionLink} />
      )}
    </>
  );
};

SidebarSection.propTypes = {
  tab: PropTypes.shape({
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    subs: PropTypes.array,
  }),
  useSidebarSectionLink: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SidebarSection;
