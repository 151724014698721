import React, { useState } from 'react';

import AsyncSelect from 'react-select/async';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { Clear } from '@mui/icons-material';

import './UserSelect.css';
import ApiManager from '../../ApiManager';

import { ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material';
import { checkEmail } from 'Account//utils';
import CustomAvatar from 'ReusableComponents/CustomAvatar';

const useStyles = makeStyles()((theme) => ({
  listItemContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    width: '100%',
  },
  itemContainer: {
    padding: 0,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(2),
    width: '100%',
  },
  itemIcon: {
    minWidth: 0,
  },
  itemAction: {
    padding: 0,
    position: 'relative',
    top: 'auto',
    transform: 'none',
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const DROPDOWN_COUNT = 50;

const UserSelect = (props) => {
  const { classes, theme: ellipsisTheme } = useStyles();
  const [searchUsername, setSearchUsername] = useState(false);

  const searchName = (username) => {
    if (username === '') {
      return;
    }
    setSearchUsername(username);
    if (username.includes('@')) {
      let isValid = checkEmail(username, () => {});

      return new Promise(function (resolve, reject) {
        let result = isValid ? [{ username: username }] : [];
        resolve(result);
      });
    }

    let request = ApiManager.get('/v3/user', { username: username, fuzzySearch: true }, props.user);

    return request
      .then((res) => {
        /* let requests = res.slice(0, DROPDOWN_COUNT).map((user) => {
          return ApiManager.get(`/v3/user/${user.id}`, null, props.user).then((profile) => {
            profile = VersionUtility.convertAccount(profile);

            user.profile = profile;
            return user;
          });
        });

        return Promise.all(requests); */
        return res.slice(0, DROPDOWN_COUNT);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };

  // handle selection
  const handleChange = (value) => {
    props.onChange(value);
  };

  const cancelUser = () => {
    props.onChange(null);
  };

  return (
    <Typography component="div" sx={props?.sx}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        value={props.value}
        inputId="select-input-123"
        formatOptionLabel={(user) =>
          user ? (
            <ListItem
              onClick={() => props.onChange(null)}
              className={classes.itemContainer}
              ContainerComponent="div"
              ContainerProps={{ className: classes.listItemContainer }}
            >
              <ListItemIcon className={classes.itemIcon}>
                <CustomAvatar user={user} size={24} />
              </ListItemIcon>
              <ListItemText primary={user.username} primaryTypographyProps={{ noWrap: true }} />
              {props.value && props.canCancel && user.id === props.value?.id ? (
                <ListItemSecondaryAction className={classes.itemAction} onClick={cancelUser} onTouchEnd={cancelUser}>
                  <Clear />
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          ) : null
        }
        getOptionValue={(user) => user.username}
        noOptionsMessage={() =>
          props.emailSwitch && typeof searchUsername?.includes === 'function' && searchUsername?.includes('@')
            ? 'Invalid email'
            : props.emailSwitch
            ? 'Type valid username or email'
            : !props.value
            ? 'Start Typing'
            : `No users found`
        }
        loadOptions={searchName}
        // onInputChange={setInputValue}
        onChange={handleChange}
        placeholder={props?.placeholder || (props.emailSwitch ? 'Type a username or email' : 'Type a username')}
        styles={{
          // Fixes the overlapping problem of the component
          container: (provided) => ({ ...provided, zIndex: 20 }),
          valueContainer: (provided) => ({ ...provided, height: 41 }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            backgroundColor: '#fff !important',
            borderRadius: '5px',
            margin: '1px 0',
          }),
          loadingIndicator: (provided) => ({ ...provided, display: 'none' }),
          singleValue: (provided) => ({ ...provided, width: '100%' }),
          input: (provided) => ({ ...provided, font: 'inherit' }),
          placeholder: (provided) => ({ ...provided, color: '#00000066' }),
        }}
        menuPosition={'fixed'}
        menuPortalTarget={props.portalRef ? props.portalRef.current : undefined}
        theme={(theme) => ({
          ...theme,
          colors: { ...theme.colors, primary: ellipsisTheme.palette.primary.main },
        })}
        components={props.components}
      />
    </Typography>
  );
};

UserSelect.propTypes = {
  canCancel: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  portalRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default UserSelect;
