import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import ApiManager from 'ApiManager';
import { useAuth, useMinimalAuth } from 'hooks';

const MAX_REQUESTS = 10;
const TIMEOUT = 500;
const EXPIRY_TIME = 86400000;

const ContextProfiles = createContext();

const b64ToBlob = ({ b64, mime = 'data:image/jpeg;' }) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = (err) => reject(err);
    img.onload = () => {
      const canvas = new OffscreenCanvas(img.width, img.height);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.convertToBlob().then(resolve).catch(reject);
    };
    img.src = mime + 'base64,' + b64;
  });
};

const ProfileContext = ({ children }) => {
  const user = useMinimalAuth();

  const loading = useRef([]);
  const [profiles, setProfiles] = useState({});

  const timerRef = useRef(null);

  const handleUser = useCallback(
    ({ blob, user: internalUser, status = 'success' }) => {
      const date = new Date();

      return {
        profile: user?.id !== internalUser?.id ? internalUser : user,
        cachedImageUrl: !!blob ? URL.createObjectURL(blob) : null,
        expiry: date.getTime() + EXPIRY_TIME,
        status,
      };
    },
    [user]
  );

  const handleUserLoad = useCallback(async () => {
    Promise.allSettled(
      loading.current?.slice(0, MAX_REQUESTS).map((o) => {
        const id = o.id;
        return ApiManager.get(o.type === 'userGroup' ? `/v3/organization/group/${id}` : `/v3/user/${id}`, null, user)
          .then((res) => {
            return b64ToBlob({ b64: res?.profile?.picture })
              .then((blob) => handleUser({ blob, user: res }))
              .catch((err) => handleUser({ user: res, status: 'noImage' }));
          })
          .catch((err) => {
            console.error(err);
            return handleUser({ user: { id }, status: 'errored' });
          });
      })
    )
      .then((results) => {
        const rest = loading.current?.slice(MAX_REQUESTS);
        loading.current = rest;

        if (rest?.length === 0) {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }

        setProfiles((oldProfiles) => ({
          ...oldProfiles,
          ...results.reduce((acc, result) => {
            if (result.status === 'fulfilled' && result.value) {
              URL.revokeObjectURL(oldProfiles?.[result?.value.profile.id]?.cachedImageUrl);

              return { ...acc, [result?.value.profile.id]: result?.value };
            }

            return acc;
          }, {}),
        }));
      })
      .catch((err) => console.error(err));
  }, [handleUser, user]);

  const setLoading = useCallback(
    (userAvatar, update) => {
      if (!!update || (!loading.current.map((x) => x.id).includes(userAvatar.id) && !profiles[userAvatar.id])) {
        if (!timerRef.current) {
          timerRef.current = setInterval(handleUserLoad, TIMEOUT);
        }

        loading.current = [...loading.current, userAvatar];
      }
    },
    [handleUserLoad, profiles]
  );

  const getProfile = useCallback(
    (props) => {
      const { userAvatar, update = false } = { ...props };

      if (!!userAvatar) {
        const profile = profiles?.[userAvatar.id];

        if (!!profile && update === false) {
          if (profile?.expiry <= new Date().getTime() && profile?.cachedImageUrl !== null) {
            console.log('expired');
            setLoading(userAvatar, true);
          } else {
            return profile;
          }
        } else {
          setLoading(userAvatar, update);
        }
      }
      return {};
    },
    [profiles, setLoading]
  );

  return <ContextProfiles.Provider value={{ getProfile }}>{children}</ContextProfiles.Provider>;
};

export const useProfileContext = (userAvatar) => {
  const context = useContext(ContextProfiles);
  const { getProfile } = { ...context };
  const user = useAuth();

  if (context === undefined) {
    throw new Error('useProfileContext must be used within a ProfileContext.Provider');
  }

  const profile = useMemo(() => getProfile({ userAvatar }), [getProfile, userAvatar.id]);

  return profile;
  // return profile;
};

export const useUpdateProfileContext = () => {
  const context = useContext(ContextProfiles);

  if (context === undefined) {
    throw new Error('useUpdateProfileContext must be used within a ProfileContext.Provider');
  }

  return context?.getProfile;
};

export default ProfileContext;
