import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import capitalize from 'lodash/capitalize';

import Sidebar from '../../ReusableComponents/SettingsSidebar';
import { useAuth } from '../AuthContext';

import AccountSecurity from './AccountSecurity';
import Profile from './Profile';
import Developers from './Developers';
import Applications from './Applications';
import ConnectionKeys from './ConnectionKeys';
import Admins from './Admins';
import UserGroups from './UserGroups';

import PersonalAccessTokens from './PersonalAccessTokens';
import NewOAuthApp from './NewOAuthApp';
import { useHistory } from 'react-router-dom';
import SentryRoute from 'ReusableComponents/SentryRoute/SentryRoute';
import ApiManager from 'ApiManager';
import Organizations from './Organizations';
import MyGroups from './MyGroups';
import { Lock } from '@mui/icons-material';
// import ChangeHostLocation from './ChangeHostLocation/ChangeHostLocation';

const ROOT_PATH = '/account-settings';

const useStyles = makeStyles()((theme) => ({
  gridRoot: {
    maxWidth: '1024px',

    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));

const AccountManagement = () => {
  const { classes } = useStyles();
  const user = useAuth();
  const history = useHistory();

  const tabs = useMemo(
    () => [
      ...[
        { type: 'subheading', title: 'Account' },
        {
          name: 'Profile',
          path: 'profile',
          component: Profile,
          type: 'tab',
        },
        {
          name: 'Account Security',
          path: 'security',
          component: AccountSecurity,
          type: 'tab',
        },
      ],
      ...[
        { type: 'subheading', title: 'Organization' },
        ...(user?.type !== 'organization'
          ? [
              {
                name: 'Organizations',
                path: 'organizations',
                component: Organizations,
                type: 'tab',
              },

              {
                name: 'My Groups',
                path: 'my-groups',
                component: MyGroups,
                type: 'tab',
              },
            ]
          : [
              {
                name: 'Organization admins',
                path: 'admins',
                component: Admins,
                type: 'tab',
              },
              {
                name: 'Organization User groups',
                path: 'user-groups',
                component: UserGroups,
                type: 'tab',
              },
            ]),
      ],
      ...[
        ...(user?.type !== 'organization' && user?.username === 'admin'
          ? [{ type: 'subheading', title: 'Development' }]
          : []),
        ...(user?.type !== 'organization'
          ? [
              {
                name: 'Applications',
                path: 'applications',
                component: Applications,
                type: 'tab',
              },
              {
                name: 'Personal access tokens',
                path: 'personal-access-tokens',
                component: PersonalAccessTokens,
                type: 'tab',
              },
              ...(user?.commercial
                ? [
                    {
                      name: 'OAuth',
                      path: 'OAuth',
                      component: Developers,
                      type: 'tab',
                      endIcon: Lock,
                    },
                  ]
                : []),
            ]
          : []),
        ...(user?.username === 'admin'
          ? [
              {
                name: 'External connection keys',
                path: 'connection-keys',
                component: ConnectionKeys,
                type: 'tab',
              },
            ]
          : []),
      ],
    ],
    [user?.commercial, user?.type, user?.username]
  );

  const filteredTabs = useMemo(() => tabs?.filter((t) => t.type === 'tab'), [tabs]);

  if (!user) {
    let url = `${window.location.pathname}${window.location.search}`;
    if (url.includes('account-settings')) {
      history.push('/login', { from: url });
    }
    return null;
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <Grid container spacing={4} className={classes.gridRoot}>
        <Grid item md={3} sm={12} xs={12}>
          <Sidebar>
            <Sidebar.Title user={user} variant="account" />
            <Sidebar.SectionContainer>
              {tabs.map((tab, i) => (
                <Sidebar.Section tab={tab} key={tab.name} rootPath={ROOT_PATH} useSidebarSectionLink={(path) => path} />
              ))}
            </Sidebar.SectionContainer>
          </Sidebar>
        </Grid>

        <Grid item md={9} sm={12} xs={12} className={classes.gridMainBody}>
          <Switch>
            {filteredTabs.map((tab, i) => (
              <SentryRoute exact path={`${ROOT_PATH}/${tab.path}`} key={tab.name}>
                <Helmet>
                  <title>
                    {capitalize(tab.name)} settings - {ApiManager?.displayName ?? 'Ellipsis Drive'}
                  </title>
                </Helmet>
                <tab.component name={tab.name} path={`${ROOT_PATH}/${tab.path}`} />
              </SentryRoute>
            ))}

            <SentryRoute exact path={ROOT_PATH + '/developers/new-oauth-app'}>
              <NewOAuthApp />
            </SentryRoute>

            <SentryRoute path={ROOT_PATH}>
              <Redirect to={`${ROOT_PATH}/${filteredTabs?.[0].path}`} />
            </SentryRoute>
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountManagement;
