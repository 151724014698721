import React, { useState, useMemo } from 'react';

import { AvatarWithInfo, SectionTitle } from '../../../ReusableComponents';
import { List, ListItem, ListItemText, IconButton, ListItemAvatar, Tooltip, Typography } from '@mui/material';

import { useAuth, useAuthFuncs } from '../../AuthContext';

import { Group } from '@mui/icons-material';
import { GroupDialog } from '../UserGroups/UserGroups';

const MyGroups = () => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const { fetchUserInfo } = useAuthFuncs();
  const user = useAuth();

  const onDeleteSelf = () => {
    fetchUserInfo();
  };

  const groups = useMemo(() => user?.organizations?.flatMap((o) => o?.groups) ?? [], [user?.organizations]);

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>My Groups</SectionTitle.SectionTitleText>
      </SectionTitle>
      {groups?.length > 0 ? (
        <List sx={{ mb: 2 }}>
          {groups?.map((o) => (
            <ListItem key={o.id}>
              <ListItemAvatar>
                <AvatarWithInfo user={o} ButtonProps={{ sx: { m: -1 } }} />
              </ListItemAvatar>
              <ListItemText primary={o.username} />
              <Tooltip title={'Manage users'}>
                <span>
                  <IconButton
                    onClick={() => {
                      setOpenAddUser(o);
                    }}
                  >
                    <Group />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>You are not in a group yet</Typography>
      )}
      <GroupDialog openAddUser={openAddUser} setOpenAddUser={setOpenAddUser} onDeleteSelf={onDeleteSelf} />
    </div>
  );
};

export default MyGroups;
