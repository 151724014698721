import React from 'react';

import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import SidebarSection from './SidebarSection';
import SidebarTitle from './SidebarTitle';

const useSidebarStyles = makeStyles({ name: 'Sidebar' })((theme) => ({
  root: {
    margin: 'auto',
    position: 'sticky',
    top: '32px',
  },
}));

const Sidebar = ({ children }) => {
  const { classes } = useSidebarStyles();

  return <div className={classes.root}>{children}</div>;
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

const SidebarSectionContainer = ({ children }) => <div>{children}</div>;

SidebarSectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

Sidebar.Title = SidebarTitle;
Sidebar.SectionContainer = SidebarSectionContainer;
Sidebar.Section = SidebarSection;

export default Sidebar;
